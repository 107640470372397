import React from 'react'
import { Pagination } from 'react-bootstrap'
import { useWindowSize } from 'hooks';
import { range } from 'utils/range';



const Paginacion = (props) => {
	/*
		*Comportamiento*
		Se muestran 
		- Primera (disabled si se está en la primera página)
		- Anterior (disabled si se está en la primera página)
		- 2 páginas a la izquierda (cuando se puede)
		- 2 a la derecha de la actual (cuando se puede)
		- Siguiente (disabled si se está en la última página)
		- Última (disabled si se está en la última página)
	*/
	const {pagina, setPagina, numResultados, deACuantos} = props

	const windowSize = useWindowSize();
	const isMobile = windowSize.width < 768
	const lastPage = Math.ceil(numResultados / deACuantos) || 1
	const isLastPage = pagina === lastPage
	const isFirstPage = pagina === 1

	return (
		<Pagination size={isMobile ? 'sm' : null}>
			{isMobile ? (
				<Pagination.First
					onClick={() => setPagina(1)}
					disabled={isFirstPage}
				/>
			) : (
				<Pagination.Item
					onClick={() => setPagina(1)}
					disabled={isFirstPage}
				>
					Primera
				</Pagination.Item>
			)}
			{isMobile ? (
				<Pagination.Prev
					onClick={() => setPagina(pagina - 1)}
					disabled={isFirstPage}
				/>
			) : (
				<Pagination.Item
					onClick={() => setPagina(pagina - 1)}
					disabled={isFirstPage}
				>
					Anterior
				</Pagination.Item>
			)}
			{range(pagina - 2, pagina + 2).map(step => {
				return (
					(step > 0 && step <= lastPage) && (
						<Pagination.Item
							onClick={() => setPagina(step)}
							key={step}
							disabled={step === pagina}
						>
							{step}
						</Pagination.Item>
					)
				)
			})}
			{isMobile ? (
				<Pagination.Next
					onClick={() => setPagina(pagina + 1)}
					disabled={isLastPage}
				/>
			) : (
				<Pagination.Item
					onClick={() => setPagina(pagina + 1)}
					disabled={isLastPage}
				>
					Siguiente
				</Pagination.Item>
			)}

			{isMobile ? (
				<Pagination.Last
					onClick={() => setPagina(lastPage)}
					disabled={isLastPage}
				/>
			) : (
				<Pagination.Item
					onClick={() => setPagina(lastPage)}
					disabled={isLastPage}
				>
					Última
				</Pagination.Item>
			)}
		</Pagination>
	)
}

export default Paginacion
