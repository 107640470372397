import React from "react"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

function NoMatch(props) {
	return (
		<Container fluid id="no-match" className="vh-100">
			<Row>
				<Col className="mt-2">
					Esta pagina no existe.
				</Col>
			</Row>
		</Container>
	)
}

export default NoMatch
