function MenuSeparator(props) {
	return <div style={{
		clear: 'both',
    	position: 'relative',
    	margin: '.8rem 0',
    	backgroundColor: '#ddd',
    	height: '1px'
	}}></div>
}

export default MenuSeparator