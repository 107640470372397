import React, { useRef, useState } from 'react'
import Button from 'react-bootstrap/Button'
import { convertBytesToKB } from 'utils/general';
import './FileUpload.css'

const DEFAULT_MAX_FILE_SIZE_IN_BYTES = 5000000;

const convertNestedObjectToArray = (nestedObj) =>
	Object.keys(nestedObj).map((key) => nestedObj[key]);

// Inspirado en:
// https://dev.to/chandrapantachhetri/responsive-react-file-upload-component-with-drag-and-drop-4ef8
const FileUpload = ({
	title,
	dragDropLabel = 'Arrastrar a esta área o',
	buttonText = 'Subir',
	buttonTextPlural = 'archivos',
	buttonTextSingular = 'archivo',
	buttonIconClass = 'fas fa-file-upload',
	updateFiles = null,
	maxFileSizeInBytes = DEFAULT_MAX_FILE_SIZE_IN_BYTES,
	...otherProps
}) => {
	const fileInputField = useRef(null)
	const [files, setFiles] = useState({})

	const handleUploadBtnClick = () => {
		fileInputField.current.click()
	};

	const handleNewFileUpload = (e) => {
		const { files: newFiles } = e.target
		if (newFiles.length) {
			let updatedFiles = addNewFiles(newFiles)
			setFiles(updatedFiles)
			callUpdateFilesCb(updatedFiles)
		}
	}

	const addNewFiles = (newFiles) => {
		for (let file of newFiles) {
			if (file.size <= maxFileSizeInBytes) {
				if (!otherProps.multiple) {
					return { file }
				}
				files[file.name] = file
			}
		}
		return { ...files }
	};

	const callUpdateFilesCb = (files) => {
		const filesAsArray = convertNestedObjectToArray(files)
		updateFiles(filesAsArray)
	};

	const removeFile = (fileName) => {
		delete files[fileName];
		setFiles({ ...files });
		callUpdateFilesCb({ ...files });
	};

	function handleDragEnter(e) {
		e.currentTarget.parentElement.classList.add('dragging-over');
	}

	function handleDragLeave(e) {
		e.currentTarget.parentElement.classList.remove('dragging-over');
	}

	function handleDragDrop(e) {
		e.currentTarget.parentElement.classList.remove('dragging-over');
	}

	return (
		<>
			<section className="file-upload-container">
				<label className="file-upload-title">{title}</label>
				<p className="file-upload-dragdrop-text">{dragDropLabel}</p>
				<Button variant="primary" onClick={handleUploadBtnClick}>
					<i className={buttonIconClass} />
					<span> {buttonText} {otherProps.multiple ? buttonTextPlural : buttonTextSingular}</span>
				</Button>
				<input className="file-upload-file-selector"
					type="file"
					ref={fileInputField}
					title=""
					value=""
					onDragEnter={e => handleDragEnter(e)}
      				onDragLeave={e => handleDragLeave(e)}
					onDrop={e => handleDragDrop(e)}
					{...otherProps}
					onChange={handleNewFileUpload}
				/>
			</section>

			{/*Preview de los archivos*/}
			<article className="file-upload-preview-container">
				<span>Archivo seleccionado</span>
				<section className="file-upload-preview-list">
					{Object.keys(files).map((fileName, index) => {
						let file = files[fileName];
						let isImageFile = file.type.split("/")[0] === "image";
						console.log('fileName', fileName)
						return (
							<section key={fileName} className="preview-container">
								<div>
									{isImageFile && (
										<img className="img-preview"
											src={URL.createObjectURL(file)}
											alt={`file preview ${index}`}
										/>
									)}
									<div className={isImageFile ? 'is-image-file' : ''}>
										<span className="filename">{file.name}</span>
										<aside>
											<span className="filesize">{convertBytesToKB(file.size)} kb</span>
											<i className="file-remove fas fa-trash-alt" onClick={() => removeFile(fileName)} />
										</aside>
									</div>
								</div>
							</section>
						);
					})}
				</section>
			</article>
		</>
	);
};

export default FileUpload