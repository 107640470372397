import { useEffect, useState } from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import api from 'api/api'


function TestComponent(props) {
	

	return (
		<Container fluid id="test" className="vh-100">
			<Row className="d-flex no-gutters">
				<Col className="mt-2">
					Test varios
				</Col>
			</Row>
		</Container>
	);
}

export default TestComponent