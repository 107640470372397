import './Panel.css'

function PanelFooter(props) {
	return (
		<div className="panel-footer">
			{props.children}
		</div>
	)
}

export default PanelFooter