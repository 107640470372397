import { LOCALSTORAGE_KEY } from "./constants"
import { getItem } from "./localstorage"

export const isLoggedIn = () => {
	return getItem(LOCALSTORAGE_KEY) != null
}

export const handleChangeNumeric = (val, setFn) => {
	const re = /^[0-9\b]+$/;

	if (val === '') {
		setFn(0)
	} 
	
	if (re.test(val)) {
		setFn(parseInt(val))
	}
}

export const convertBytesToKB = (bytes) => Math.round(bytes / 1000)

export const isFunction = (fn) => {return typeof fn === 'function'}